@import url('//hello.myfonts.net/count/4183eb');

@font-face {
  font-family: 'Adelle';
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  src: url('adelle_500.woff2') format('woff2'),
    url('adelle_500.woff') format('woff');
}

@font-face {
  font-family: 'Adelle';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url('adelle_700.woff2') format('woff2'),
    url('adelle_700.woff') format('woff');
}


@font-face {
  font-family: "GT Walsheim";
  src: url("gt_walsheim_regular.woff") format("woff"),
  url("gt_walsheim_regular.ttf") format("truetype"),
  url("gt_walsheim_regular.otf") format("opentype");
}

@font-face {
  font-family: "GT Walsheim";
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  src: url("GTWalsheimRegular-Oblique.woff2") format("woff2"),
  url("GTWalsheimRegular-Oblique.woff") format("woff"),
  url("GTWalsheimRegular-Oblique.ttf") format("truetype");
}

@font-face {
  font-family: "GT Walsheim Trial";
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url("GTWalsheimBold-Trial.woff2") format("woff2"),
  url("GTWalsheimBold-Trial.woff") format("woff"),
  url("GTWalsheimBold-Trial.ttf") format("truetype");
}

@font-face {
  font-family: "GT Walsheim Trial";
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  src: url("GTWalsheimMedium-Trial.woff2") format("woff2");
}

@font-face {
  font-family: "icomoon";
  src: url("./icomoon.eot");
  src: url("./icomoon.eot?#iefix") format("embedded-opentype"), url("./icomoon.woff")
  format("woff"), url("./icomoon.ttf") format("truetype"), url("./icomoon.svg#icomoon")
  format("svg");
  font-weight: normal;
  font-style: normal;
}
